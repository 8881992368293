import {
  AddTrainingOutcomeResult,
  BookedEventResult,
  CertificatesResult,
  Certification,
  CertifiedStep,
  ClubListing,
  ClubListingUnknown,
  ClubMultiListing,
  ClubPartnershipBusinessSupportContent,
  ClubPartnershipProgramStats,
  ClubPartnershipStandardContent,
  ClubProgram,
  ClubProgramInStudioEmptyState,
  ClubSingleListing,
  ContactUsStep,
  DashboardPageClubResponse,
  DashboardPageInstructorResponse,
  EnquireAboutProgramResponse,
  EnquiryHistoryClubListing,
  Event,
  ErrorResult,
  EventDetailsResult,
  EventsResult,
  EventSummary,
  FinalizeUploadResult,
  GetHeaderResponse,
  GetPresignedUrlResult,
  Image,
  InitializeUploadResult,
  LinkTile,
  Locale,
  Program,
  QwsEventDetailsResult,
  ReceivedCertificationOutcomeStep,
  ReceivedTrainingOutcomeStep,
  SearchClubJobListingsSingleResult,
  SearchClubListingsSingleResult,
  SearchClubListingsSort,
  SearchClubListingsWithFilterResult,
  SearchClubProfileSingleResult,
  SearchClubsWithFilterResult,
  SharedNavigation,
  SubmitCertificationVideoStep,
  TrainerBookingsResult,
  TrainingJourneyResult,
  TrainingJourneysResult,
  UserProfile,
  UserResult,
  QwsEvent,
  QwsBookedEventResult,
  QwsImtTrainerBookingsResult,
  QwsInstructorBookedEventResult,
  QwsImtInstructorBookingsResult,
  QwsInstructorBookedEventSummary,
} from 'src/generated/graphql'
import { ClubProfile, InstructorProfile } from 'src/hooks/useProfile'
import { SearchClubMultiListingsSingleResult } from 'src/components/findWork/MultiListingCard'
import { SearchClubSingleListingsSingleResult } from 'src/components/findWork/SingleListingCard'
import { SummaryStepNumbers } from 'src/modules/TrainingAndAssessments/Training/Content/Summary/Summary.types'
import { EventType } from '../contexts/EventTypeProvider'
import { BookedTrainerProps } from 'src/modules/TrainingAndAssessments/Trainer/List/BookedTrainerTable/BookedTrainer/BookedTrainer.types'
import { QwsBookedTrainerProps } from 'src/modules/TrainingAndAssessments/Trainer/List/BookedTrainerTable/QwsBookedTrainer/QwsBookedTrainer.types'
import { QWSBookedTrainingProps } from 'src/modules/TrainingAndAssessments/Training/List/BookedTrainingTable/BookedTraining/BookedTraining.types'

export const isErrorResult = (
  result: unknown
): result is Pick<ErrorResult, '__typename' | 'type' | 'message'> => {
  return (result as ErrorResult)?.__typename === 'ErrorResult'
}

export const isTeachingProfile = (
  profile: UserProfile
): profile is InstructorProfile => {
  return profile?.__typename === 'UserProfileTeaching'
}

export const isClubProfile = (profile: UserProfile): profile is ClubProfile => {
  return profile?.__typename === 'UserProfileClub'
}

const locales = Object.values(Locale)
export const isLocale = (value: Locale | string): value is Locale =>
  locales.includes(value as Locale)

export const isProgram = (
  program: Program | Certification
): program is Program => {
  return program.__typename === 'Program'
}

export const isCertification = (
  program: Program | Certification
): program is Certification => {
  return program.__typename === 'Certification'
}

export const isSingleListing = (
  listing: ClubListing | EnquiryHistoryClubListing
): listing is ClubSingleListing => {
  return listing.__typename === 'ClubSingleListing'
}

export const isMultiListing = (
  listing: ClubListing | EnquiryHistoryClubListing
): listing is ClubMultiListing => {
  return listing.__typename === 'ClubMultiListing'
}

export const isJobListingSearchResult = (
  result: SearchClubListingsSingleResult
): result is SearchClubJobListingsSingleResult => {
  return result.__typename === 'SearchClubJobListingsSingleResult'
}

export const isMissingListing = (
  listing: ClubListing | EnquiryHistoryClubListing
): listing is ClubListingUnknown => {
  return listing.__typename === 'ClubListingUnknown'
}

export const isSingleListingSearchResult = (
  result: any
): result is SearchClubSingleListingsSingleResult => {
  return result.listing && isSingleListing(result.listing)
}

export const isMultiListingSearchResult = (
  result: any
): result is SearchClubMultiListingsSingleResult => {
  return result.listing && isMultiListing(result.listing)
}

export const isClubProfileSearchResult = (
  result: any
): result is SearchClubProfileSingleResult => {
  return (
    result.__typename === 'SearchClubProfileSingleResult' &&
    result.profile &&
    isClubProfile(result.profile)
  )
}

export const isImage = (image: any): image is Image => {
  return image?.__typename === 'Image'
}

export const isDashboardPageClubResponse = (
  result: any
): result is DashboardPageClubResponse => {
  return result.__typename === 'DashboardPageClubResponse'
}

export const isDashboardPageInstructorResponse = (
  result: any
): result is DashboardPageInstructorResponse => {
  return result.__typename === 'DashboardPageInstructorResponse'
}

export const isEnquireAboutProgramResponse = (
  result: any
): result is EnquireAboutProgramResponse => {
  return result.__typename === 'EnquireAboutProgramResult'
}

const searchClubListingsSortValues = Object.values(SearchClubListingsSort)
export const isSearchClubListingsSort = (
  value: any
): value is SearchClubListingsSort =>
  searchClubListingsSortValues.includes(value)

export const isHeaderContentResponse = (
  result: any
): result is GetHeaderResponse => {
  return result.__typename === 'GetHeaderResult'
}

export const isClubPartnershipProgramStats = (
  result: any
): result is ClubPartnershipProgramStats => {
  return result.__typename === 'ClubPartnershipProgramStats'
}

export const isClubPartnershipStandardContent = (
  result: any
): result is ClubPartnershipStandardContent => {
  return result.__typename === 'ClubPartnershipStandardContent'
}

export const isClubPartnershipBusinessSupportContent = (
  result: any
): result is ClubPartnershipBusinessSupportContent => {
  return result.__typename === 'ClubPartnershipBusinessSupportContent'
}

export const isClubProgram = (
  program: ClubProgram | ClubProgramInStudioEmptyState
): program is ClubProgram => {
  return program?.__typename === 'ClubProgram'
}

export const isSharedNavigationResult = (
  sharedNavigation: SharedNavigation
): sharedNavigation is SharedNavigation => {
  return sharedNavigation?.__typename === 'SharedNavigation'
}

export const isLinkTile = (result: any): result is LinkTile => {
  return result.__typename === 'LinkTile'
}

export const isTrainingJourneysResult = (
  trainingJourneysResult: any
): trainingJourneysResult is TrainingJourneysResult => {
  return trainingJourneysResult?.__typename === 'TrainingJourneysResult'
}

export const isTrainingJourneyResult = (
  trainingJourneyResult: any
): trainingJourneyResult is TrainingJourneyResult => {
  return trainingJourneyResult?.__typename === 'TrainingJourneyResult'
}

export const isTrainerBookingsResult = (
  TrainerBookingsResult: any
): TrainerBookingsResult is TrainerBookingsResult => {
  return TrainerBookingsResult?.__typename === 'TrainerBookingsResult'
}

export const isQwsImtTrainerBookingsResult = (
  trainerBookingsResult: any
): trainerBookingsResult is QwsImtTrainerBookingsResult => {
  return trainerBookingsResult?.__typename === 'QwsImtTrainerBookingsResult'
}

export const isQwsImtInstructorBookingsResult = (
  instructorBookingsResult: any
): instructorBookingsResult is QwsImtInstructorBookingsResult => {
  return (
    instructorBookingsResult?.__typename === 'QwsImtInstructorBookingsResult'
  )
}

export const isQWSBookedTrainingProps = (
  summary: any
): summary is QWSBookedTrainingProps => {
  return summary?.__typename === 'QWSBookedTrainingProps'
}

export const isContactUsStep = (
  contactUsStep: any
): contactUsStep is ContactUsStep => {
  return contactUsStep?.__typename === 'ContactUsStep'
}

export const isInitializeUploadResult = (
  result: any
): result is InitializeUploadResult => {
  return result.__typename === 'InitializeUploadResult'
}

export const isGetPresignedUrlResult = (
  result: any
): result is GetPresignedUrlResult => {
  return result.__typename === 'GetPresignedUrlResult'
}

export const isFinalizeUploadResult = (
  result: any
): result is FinalizeUploadResult => {
  return result.__typename === 'FinalizeUploadResult'
}

export const isReceivedCertificationOutcome = (
  step: any
): step is ReceivedCertificationOutcomeStep => {
  return step.__typename === 'ReceivedCertificationOutcomeStep'
}

export const isTrainingOutcome = (
  step: any
): step is ReceivedTrainingOutcomeStep => {
  return step.__typename === 'ReceivedTrainingOutcomeStep'
}

export const isSubmitCertificationVideoStep = (
  step: any
): step is SubmitCertificationVideoStep => {
  return step.__typename === 'SubmitCertificationVideoStep'
}

export const isCertifiedStep = (step: any): step is CertifiedStep => {
  return step.__typename === 'CertifiedStep'
}

export const isTrainingJourneyStepNumber = (
  step: any,
  eventType: EventType
): step is SummaryStepNumbers => {
  if (eventType === EventType.Signature)
    return (
      step === 100 ||
      step === 110 ||
      step === 120 ||
      step === 130 ||
      step === 140 ||
      step === 150 ||
      step === 160 ||
      step === 170
    )
  else if (eventType === EventType.LimitedEdition)
    return (
      step === 200 ||
      step === 210 ||
      step === 220 ||
      step === 250 ||
      step === 260 ||
      step === 270
    )
  else if (eventType === EventType.Innovation)
    return (
      step === 300 ||
      step === 310 ||
      step === 320 ||
      step === 350 ||
      step === 360 ||
      step === 370
    )
  return false
}

export const isAddTrainingOutcomeResult = (
  result: any
): result is AddTrainingOutcomeResult => {
  return result.__typename === 'AddTrainingOutcomeResult'
}

export const isEventSummary = (result: any): result is EventSummary => {
  return result.__typename === 'EventSummary'
}

export const isEventsResult = (result: any): result is EventsResult => {
  return result.__typename === 'EventsResult'
}

export const isEventDetailsResult = (
  result: any
): result is EventDetailsResult => {
  return result.__typename === 'EventDetailsResult'
}

export const isQwsEventDetailsResult = (
  result: any
): result is QwsEventDetailsResult => {
  return result.__typename === 'QWSEventDetailsResult'
}

export const isQwsEvent = (result: any): result is QwsEvent => {
  return result.__typename === 'QWSEvent'
}

export const isEvent = (result: any): result is Event => {
  return result.__typename === 'Event'
}

export const isBookedEventResult = (
  result: any
): result is BookedEventResult => {
  return result.__typename === 'BookedEventResult'
}

export const isCertificatesResult = (
  result: any
): result is CertificatesResult => {
  return result.__typename === 'CertificatesResult'
}

export const isUserResult = (result: any): result is UserResult => {
  return result.__typename === 'UserResult'
}

export const isSearchClubsWithFilterResult = (
  result: any
): result is SearchClubsWithFilterResult => {
  return result.__typename === 'SearchClubsWithFilterResult'
}

export const isSearchClubListingsWithFilterResult = (
  result: any
): result is SearchClubListingsWithFilterResult => {
  return result.__typename === 'SearchClubListingsWithFilterResult'
}

export const isQwsBookedEventResult = (
  result: any
): result is QwsBookedEventResult => {
  return result.__typename === 'QwsBookedEventResult'
}

export const isBookedTrainerProps = (
  eventSummary: any
): eventSummary is BookedTrainerProps => {
  return eventSummary.__typeOrigin === 'EventSummary'
}

export const isQwsBookedTrainerProps = (
  eventSummary: any
): eventSummary is QwsBookedTrainerProps => {
  return eventSummary.__typeOrigin === 'QWSTrainerBookedEventSummary'
}

export const isQwsInstructorBookedEventResult = (
  result: any
): result is QwsInstructorBookedEventResult => {
  return result?.__typename === 'QwsInstructorBookedEventResult'
}
export const isQwsInstructorBookedEventSummary = (
  result: any
): result is QwsInstructorBookedEventSummary => {
  return result?.__typename === 'QWSInstructorBookedEventSummary'
}
